<template>
  <div
    class="default-layout page-layout"
    :class="{ 'disable-menu': disableMenu }"
  >
    <a-config-provider>
      <a-layout>
        <a class="menu_mobile" @click="collapsed = !collapsed">
          <!-- <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" /> -->
          <img :src="logo" />
        </a>
        <Sider></Sider>
        <a-layout>
          <Header />
          <a-layout-content :class="{ collapsed: $store.state.collapsed }">
            <template v-if="hasSlot('breadcrumb')">
              <div class="px-4">
                <slot name="breadcrumb"></slot>
              </div>
            </template>
            <template v-if="hasSlot('toolbar')">
              <div class="mx-4 mb-4 b-6 bg-white pl-4 pr-4">
                <slot name="toolbar"></slot>
              </div>
            </template>
            <slot></slot>
          </a-layout-content>
        </a-layout>
      </a-layout>
      <template #renderEmpty>
        <a-empty
          :image="imgEmpty"
          :image-style="{
            height: '150px',
          }"
        />
      </template>
    </a-config-provider>
  </div>
</template>

<script>
import Sider from '@/components/Layout/Sider'
// import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import Header from '@/components/Layout/Header'
import { config } from '@/config/bzbsconfig.js'
export default {
  props: {
    breadcrumb: {
      type: String,
    },
    disableMenu: Boolean,
    breadcrumbPropsMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Sider,
    // VBreadcrumb: Breadcrumb,
    Header,
  },
  data() {
    return {
      logo:
        config.bzbBlobUrl +
        '/themebackoffice/assets/img/logo-icon.svg',
      imgEmpty:
        config.bzbBlobUrl +
        '/themebackoffice/assets/img/empty.svg',
      datapermission: JSON.parse(sessionStorage.getItem('permissionbyapp')),
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(newValue) {
        this.$store.commit('setCollapsed', newValue)
      },
    },
    isSearch: {
      get() {
        return this.$store.state.isSearch
      },
      set(newValue) {
        this.$store.commit('setIsSearch', newValue)
      },
    },
  },
  methods: {
    hasSlot: function (name = 'default') {
      return !!this.$slots[name]
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-layout {
  display: none;
}
</style>
