<template>
  <AppLayout :disable-menu="true">
    <a-form layout="vertical">
      <div class="container-mastersettings">
        <div class="row wrapper-container mb-0">
          <div class="flex-container">
            <div class="flex-item">
              <Breadcrumb
                :title="title"
                :databreadcrumbs="databreadcrumbs"
              ></Breadcrumb>
            </div>
            <div class="flex-item d-flex align-items-center justify-end">
              <a-button
                class="create-btn ml-4"
                type="primary"
                @click="openModal()"
              >
                <plus-outlined class="plus-icon" />
                <span class="create-span">Create New</span>
              </a-button>
            </div>
          </div>
        </div>

        <div class="row row-container">
          <div class="col-12">
            <div class="bg-white card-content">
              <a-table
                :dataSource="projectList"
                :columns="columns"
                :width="'100%'"
                :pagination="{
                  pageSize: defaultPageSize,
                  current: currentPage,
                }"
                @change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'is_active'">
                    <a-tag
                      v-if="record.is_active"
                      :color="'green'"
                      class="tag-status"
                    >
                      <a-badge :color="'green'" :text="'Active'" />
                    </a-tag>
                    <a-tag v-else :color="'red'" class="tag-status">
                      <a-badge :color="'red'" :text="'Inctive'" />
                    </a-tag>
                  </template>
                  <template v-if="column.key === 'action'">
                    <a-tooltip
                      placement="top"
                      @click="handleClickEdit(record, 'edit')"
                    >
                      <template #title>
                        <span>Edit</span>
                      </template>
                      <a-button class="btn-icon btn-ico" type="primary" ghost
                        ><edit-outlined
                      /></a-button>
                    </a-tooltip>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </a-form>

    <a-modal
      v-model:visible="visibleModal"
      :title="false"
      :width="windowWidth > 1024 ? '650px' : '70%'"
      bodyStyle="max-height: 80vh;padding: 0;"
      centered
      :closable="false"
      :footer="null"
      class="setting-modal"
      :mask-closable="false"
    >
      <div
        class="modal-header d-flex align-items-center justify-content-between"
      >
        <div>
          <h3 class="pt-1">
            {{ isUpdate ? 'Edit Project' : 'Create Project' }}
          </h3>
        </div>
        <div class="d-flex gap-2">
          <div>Active</div>
          <a-tooltip placement="bottomRight">
            <template #title>
              <span v-if="!dataValue.is_active">Inactive</span>
              <span v-else>Active</span>
            </template>
            <a-switch v-model:checked="dataValue.is_active" />
          </a-tooltip>
        </div>
      </div>

      <a-form
        ref="formRef"
        :model="dataValue"
        name="basic"
        autocomplete="off"
        layout="vertical"
      >
        <div class="modal-container">
          <div class="row">
            <div class="col-12">
              <a-form-item
                label="Name :"
                name="name"
                :rules="[
                  { required: true, message: 'Please input your name!' },
                ]"
              >
                <a-input
                  v-model:value="dataValue.name"
                  :maxlength="255"
                  :disabled="isUpdate"
                />
              </a-form-item>
              <a-form-item
                label="Display Name :"
                name="display"
                :rules="[
                  {
                    required: true,
                    message: 'Please input your display name!',
                  },
                ]"
              >
                <a-input v-model:value="dataValue.display" :maxlength="255" />
              </a-form-item>
              <a-form-item
                label="Client ID :"
                name="client_id"
                :rules="[
                  { required: true, message: 'Please input your client id!' },
                ]"
              >
                <a-input
                  v-model:value="dataValue.client_id"
                  :maxlength="36"
                  show-count
                  placeholder="00000000-0000-0000-0000-000000000000"
                  @input="checkFormatClientID"
                />
                <div v-if="isFormatClientID" class="ant-form-item-explain">
                  <div class="ant-form-item-explain-error">
                    Your Client ID format is invalid *(00000000-0000-0000-0000-000000000000)
                  </div>
                </div>
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="row footer-modal bg-white">
          <div class="col-12 p-0 text-right">
            <a-space :size="10">
              <a-button class="close-btn" @click="closeModal()">Close</a-button>
              <a-button class="close-btn" type="primary" @click="onSubmit">
                Submit
              </a-button>
            </a-space>
          </div>
        </div>
      </a-form>
    </a-modal>
  </AppLayout>
</template>

<script setup>
import { ref, onMounted, toRaw } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import AppLayout from '@/components/Layout/default.vue'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import columnsHelper from '@/helper/columnsHelper.js'
import BzbsAnnouncement from '@/core/Announcement/callapi/BzbsAnnouncement'
import useError from '@/components/Utility/useError'
import * as AntDesignIcons from '@ant-design/icons-vue'
const { EditOutlined } = AntDesignIcons

const store = useStore()
const title = 'Master Project'
const databreadcrumbs = ref([
  {
    name: 'announcement',
    breadcrumbName: 'Announcement Board',
  },
  {
    name: null,
    breadcrumbName: 'Master Project',
  },
])
// const windowHeight = ref(window.innerHeight)
var columns = ref(columnsHelper.columnsProject())
const projectList = ref([])
const defaultPageSize = ref(10)
const currentPage = ref(1)
const { errorApi } = useError()
const visibleModal = ref(false)
const windowWidth = ref(window.innerWidth)
const isUpdate = ref(false)
const formRef = ref()
const dataValue = ref({
  is_active: false,
  name: null,
  display: null,
  client_id: null,
})

const isFormatClientID = ref(false)

function checkFormatClientID(e) {
  if (e.target.value) {
    var str = e.target.value
    let regex = new RegExp(
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
    )
    console.log('isValid')
    if (regex.test(str) == true) {
      isFormatClientID.value = false
    } else {
      isFormatClientID.value = true
    }
  } else {
    isFormatClientID.value = false
  }
}

function handleTableChange(pagination, filters, sorter, currentDataSource) {
  console.log(
    'handleTableChange : ',
    pagination,
    filters,
    sorter,
    currentDataSource,
  )
  currentPage.value = pagination.current
  defaultPageSize.value = pagination.pageSize
}

function callGetListProject() {
  return new Promise((resolve, reject) => {
    BzbsAnnouncement.apiGetListProject().subscribe({
      next: res => {
        projectList.value = res.result
        resolve(projectList)
      },
      error: err => {
        reject(err)
      },
    })
  })
}

function handleClickEdit(data, text) {
  console.log('data : ', data)
  console.log('text : ', text)
  dataValue.value = {
    is_active: data.is_active,
    name: data.name,
    display: data.display,
    client_id: data.client_id,
  }
  isUpdate.value = true
  visibleModal.value = true
}

function openModal() {
  isUpdate.value = false
  dataValue.value = {
    is_active: false,
    name: null,
    display: null,
    client_id: null,
  }
  visibleModal.value = true
}

function closeModal() {
  visibleModal.value = false
  formRef.value.resetFields()
}

function onSubmit() {
  formRef.value
    .validate()
    .then(() => {
      if(!isFormatClientID.value) {
        console.log('values', dataValue, toRaw(dataValue))
        const params = {
          name: dataValue.value.name,
          display: dataValue.value.display,
          is_active: dataValue.value.is_active,
          client_id: dataValue.value.client_id,
        }
        return new Promise((resolve, reject) => {
          BzbsAnnouncement.apiPostUpsertProject(params)
            .then(res => {
              Modal.success({
                title: 'Success',
                centered: true,
                onOk() {
                  callGetListProject()
                  closeModal()
                },
              })
              resolve(res)
            })
            .catch(err => {
              Modal.error({
                title: 'Error',
                centered: true,
                content: 'Something went wrong, Please try again.',
                onOk() {},
              })
              reject(err)
            })
        })
      }
    })
    .catch(error => {
      console.log('error', error)
    })
}
onMounted(() => {
  Promise.all([callGetListProject()])
    .then(() => {
      store.commit('setLoading', false)
    })
    .catch(err => {
      store.commit('setLoading', false)
      errorApi(err.response)
    })
})
</script>

<style lang="scss">
@import '../styles/settings.scss';
</style>
