import Account from '@/helper/AccountHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import axios from 'axios'

/**
 * @method getPermission
 */
export default {
  /**
   * Get Permission
   */
  getPermission() {
    const url =
      ConfigManager.getApiadB2CUrl() +
      '/api/v2.0/user/permission?include_agency=true'
    var header = {
      Authorization: 'Bearer ' + Account.getToken(),
    }
    return axios({
      method: 'get',
      url: url,
      headers: header,
    })
  }, 
}
