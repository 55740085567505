<template>
  <a-button @click="showModal" class="btn-preview">
    <eye-outlined />
    <span class="text-preview-button">Preview Post</span>
  </a-button>
  <a-modal
    v-model:visible="visible"
    :title="title"
    width="600px"
    bodyStyle="max-height: 80vh;padding: 0;"
    centered
    :closable="false"
    :footer="null"
    class="preview-post-modal"
  >
    <div class="modal-container">
      <div class="editor-content">
        <div v-html="content"></div>
      </div>
    </div>
    <div class="row footer-modal bg-white">
      <div
        class="show-checkbox col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
        :class="{ 'blank-footer': required == false }"
      >
        <a-checkbox v-if="required" disabled
          >Accept, Don't show again</a-checkbox
        >
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-0 text-right">
        <a-button class="close-btn" type="primary" @click="handleClose"
          >Close</a-button
        >
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    title: String,
    // updateBy: String,
    // updateDate: String,
    content: HTMLElement,
    required: Boolean,
  },
  setup() {
    const visible = ref(false)
    const showModal = () => {
      visible.value = true
    }
    const handleClose = () => {
      visible.value = false
    }
    return {
      visible,
      showModal,
      handleClose,
    }
  },
})
</script>
<style lang="scss">
.preview-post-modal {
  .btn-preview {
    margin-right: 8px;
  }
  .content-left {
    display: flex;
    justify-content: left;
  }
  .content-between {
    display: flex;
    justify-content: space-between;
  }
  .item-content {
    flex-direction: column;
  }
  .title {
    font-size: 28px;
    line-height: 1;
  }
  .sub-title {
    font-size: 16px;
    color: var(--grey-grey-6);
  }
  .ant-modal-body {
    // overflow: hidden;
    padding: 16px 30px 0 30px;
  }
  .editor-content {
    overflow: auto;
    height: 543px;
    padding: 16px 0;
  }
  .editor-content::-webkit-scrollbar {
    display: none;
  }
  .show-checkbox {
    height: 40px;
    padding: 10px 0;
  }
  .footer-modal {
    margin: 0;
    max-width: 600px;
    padding: 16px 30px;
  }
  .modal-container {
    padding: 0 30px;
  }
  ol {
    list-style: decimal !important;
    margin: 0;
    padding-left: 28px !important;
  }
  ul {
    list-style: disc !important;
    margin: 0;
    padding-left: 28px !important;
  }
  @media only screen and (max-width: 768px) {
    .close-btn {
      max-width: 100px;
    }
  }
  @media only screen and (max-width: 576px) {
    .blank-footer {
      display: none;
    }
    .close-btn {
      max-width: inherit;
    }
  }
}
</style>
