<template>
  <AppLayout :disable-menu="true">
    <div class="list-container">
      <div class="row wrapper-container mb-0">
        <Topbar @search-value="searchFilter"></Topbar>
      </div>
      <div class="row wrapper-container bg-white mt-0">
        <div class="col-12 mt-4">
          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 p-0">
              <TabVertical
                @tab-value="statusFilter"
                :activeKey="activeStatus"
                :tabList="statusList"
              ></TabVertical>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 p-0">
              <div class="col-md-12 col-12 p-0">
                <TabHorizontal
                  @tab-value="projectFilter"
                  :activeKey="activeProject"
                  :tabList="projectList"
                ></TabHorizontal>
              </div>
              <div
                v-if="!isLoading"
                class="col-md-12 col-12 p-0 content-container"
              >
                <div
                  v-for="announcement in announcementList"
                  :key="announcement.id"
                >
                  <div>
                    <Content
                      :projectList="projectList"
                      :title="announcement.title"
                      :content="announcement.content"
                      :status="announcement.status"
                      :products="announcement.show_to_project"
                      :announcementId="announcement.id"
                      :updateBy="announcement.updated_by"
                      :updateDate="announcement.updated_date"
                      :startDate="announcement.start_date_time"
                      :active="announcement.is_active"
                      :required="announcement.is_required_accept"
                      @get-list="hidePost"
                    ></Content>
                  </div>
                  <!-- <div v-else>
                    <a-skeleton active class="content-skeleton" />
                  </div> -->
                </div>

                <a-empty
                  v-if="
                    announcementData
                      ? announcementData.total_records === 0
                      : null
                  "
                  :image="nodataImage"
                  :image-style="{
                    height: '150px',
                  }"
                  class="nodata"
                />
              </div>
              <div v-else>
                <a-skeleton active class="content-skeleton" />
              </div>

              <div v-if="!isLoading" class="row pagination">
                <div
                  class="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text-left align-self-center"
                >
                  Display
                  {{ announcementData ? announcementData.result.length : 0 }}
                  items from
                  {{ announcementData ? announcementData.total_records : null }}
                  items
                </div>
                <div
                  v-if="announcementData != null"
                  class="col-xl-8 col-lg-8 col-md-9 col-sm-12 col-12 p-0 text-right"
                >
                  <a-pagination
                    :total="
                      announcementData ? announcementData.total_records : null
                    "
                    :default-current="1"
                    :current="params.page_number"
                    :page-size="params.limit"
                    :page-size-options="pageSizeOptions"
                    @change="onChangePage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import AppLayout from '@/components/Layout/default.vue'
import Topbar from '../component/Topbar.vue'
import TabVertical from '../component/TabVertical.vue'
import TabHorizontal from '../component/TabHorizontal.vue'
import Content from '../component/Content.vue'
import BzbsAnnouncement from '@/core/Announcement/callapi/BzbsAnnouncement'
import { config } from '@/config/bzbsconfig.js'
import { useStore } from 'vuex'
import useError from '@/components/Utility/useError'

let announcementData = ref()
let announcementList = ref()
let statusList = ref()
let projectList = ref()
let activeStatus = ref('All Status')
let activeProject = ref('')
let searchText = ref()
let isLoading = ref(false)
let isCalling = ref(false)
let pageSizeOptions = ['5', '10', '20', '50', '100']
const store = useStore()
const nodataImage = ref(
  config.bzbBlobUrl +
    '/themebackoffice/assets/img/empty.svg',
)
const { errorApi } = useError()

function onChangePage(pageNumber, pageSize) {
  params.page_number = pageNumber
  params.limit = pageSize
  callGetAnnouncement(params)
}
function statusFilter(name) {
  activeStatus.value = name
  params.status = name
  params.page_number = 1
  params.search = ''
  callGetAnnouncement(params)
}
function projectFilter(name) {
  activeProject.value = name
  params.project = name
  params.page_number = 1
  params.search = ''
  callGetAnnouncement(params)
}
function searchFilter(text) {
  activeStatus.value = 'All Status'
  activeProject.value = ''
  params.search = text
  params.page_number = 1
  params.project = ''
  params.status = ''
  if (searchText.value !== params.search) {
    searchText.value = text
    callGetAnnouncement(params)
  }
}
function hidePost() {
  params.page_number = 1
  params.project = ''
  params.status = ''
  callGetAnnouncement(params)
}

function callGetProject() {
  return new Promise((resolve, reject) => {
    BzbsAnnouncement.apiGetProject().subscribe({
      next: res => {
        projectList.value = res.result
        resolve(projectList)
      },
      error: err => {
        reject(err)
      },
    })
  })
}
function callGetStatus() {
  return new Promise((resolve, reject) => {
    BzbsAnnouncement.apiGetStatus().subscribe({
      next: res => {
        statusList.value = res.result
        resolve(statusList)
      },
      error: err => {
        reject(err)
      },
    })
  })
}
const params = reactive({
  project: '',
  search: '',
  status: '',
  page_number: 1,
  limit: 5,
})
function callGetAnnouncement() {
  if (isCalling.value === true) {
    return
  } else {
    return new Promise((resolve, reject) => {
      isLoading.value = true
      isCalling.value = true
      BzbsAnnouncement.apiGetAnnouncement(params).subscribe({
        next: res => {
          announcementData.value = res.data
          announcementList.value = res.data.result
          isLoading.value = false
          isCalling.value = false
          resolve(res)
        },
        error: err => {
          isLoading.value = false
          isCalling.value = false
          reject(err)
        },
      })
    })
  }
}
onMounted(() => {
  Promise.all([callGetProject(), callGetStatus(), callGetAnnouncement()])
    .then(() => {
      store.commit('setLoading', false)
    })
    .catch(err => {
      store.commit('setLoading', false)
      errorApi(err.response)
    })
})
</script>

<style lang="scss">
.list-container {
  .wrapper-container {
    border-radius: 12px;
    margin: 0;
  }
  .content-container {
    height: 680px;
    overflow: auto;
    border-bottom: inset;
  }
  .content-container::-webkit-scrollbar {
    display: none;
  }
  .pagination {
    width: 100%;
    padding: 16px;
    border-radius: 0 0 12px 12px;
    margin: 0;
  }
  .nodata > .ant-empty-image {
    // display: inline-flex;
    text-align: -webkit-center !important;
  }
  .content-skeleton {
    padding: 16px;
  }
  @media only screen and (max-width: 576px) {
    .ant-pagination > li {
      min-width: 25px;
      height: 25px;
      font-size: 12px;
      line-height: 25px;
    }
    .ant-pagination-options {
      display: none;
    }
  }
}
</style>
