import { createRouter, createWebHistory } from 'vue-router'
import AccountHelper from '@/helper/AccountHelper'

import Login from '@/module/Auth/pages/Login.vue'
import Logout from '@/module/Auth/pages/Logout.vue'
import RedirectMS from '@/module/Auth/pages/RedirectMS'
import AuthUnauthorized from '@/module/Auth/pages/AuthUnauthorized'
import Announcement from '@/module/Announcement/pages/AnnouncementBoard'
import CreatePost from '@/module/Announcement/pages/CreatePost'
import EditPost from '@/module/Announcement/pages/EditPost'
import PageNotFound from '@/module/PageNotFound/Index'
import Project from '@/module/MasterSettings/pages/Project'

import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'

const routes = [
  {
    path: '/loginad',
    redirect: '/',
  },
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: requireAuth,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
  {
    path: '/redirectms',
    name: 'redirect-ms',
    component: RedirectMS,
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: AuthUnauthorized,
  },
  {
    path: '/loginSuccess',
    redirect: '/announcement',
  },
  {
    path: '/announcement',
    name: 'announcement',
    component: Announcement,
    beforeEnter: requireAuth,
  },
  {
    path: '/announcement/create',
    name: 'createpost',
    component: CreatePost,
    beforeEnter: requireAuth,
  },
  {
    path: '/announcement/edit/:data',
    name: 'editpost',
    component: EditPost,
    beforeEnter: requireAuth,
  },
  {
    path: '/announcement/view/:data',
    name: 'viewpost',
    component: EditPost,
    beforeEnter: requireAuth,
  },
  {
    path: '/master/project',
    name: 'master-project',
    component: Project,
    beforeEnter: requireAuth,
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
function requireAuth(to, from, next) {
  var Cache = new CacheHelper(ConfigManager.getCacheType())
  console.log(to.name)
  if (
    to.name != 'redirect-ms' &&
    to.name != 'login' &&
    Cache.getCache('logout')
  ) {
    next('/logout')
  }
  if (Cache.getCache('msToken') && Cache.getCache('msUserData')) {
    const features = AccountHelper.getFeatures()
    if (features == null) {
      next('/unauthorized')
    }
    if (to.path == '/') {
      next('/announcement')
    } else {
      next()
    }
  } else {
    if (to.name == 'redirect-ms' || to.name == 'login') {
      next()
    } else {
      next('/logout')
    }
  }
}
export default router
