<template>
  <a-layout-header :class="[{ collapsed: collapsed }, $store.state.themecolor]">
    <div class="row mx-0">
      <div class="col-6 pl-0">
        <div class="action-col d-sm-block d-flex align-items-center">
          <a-select
            v-if="appList && $route.params.appid"
            :default-value="$route.params.appid"
            class="app-select"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item, index) in appList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <!-- <div class="tag-beta">
        <img src="@/assets/imgs/beta-version-2.png" />
      </div> -->
      <div class="col-6 d-flex justify-content-end pr-0">
        <div class="action-col d-flex">
          <a-dropdown v-if="user" class="dropdownpad">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <div class="account-text d-sm-block">
                <span class="account-name">
                  {{
                    user.idTokenClaims.given_name
                      ? user.idTokenClaims.given_name
                      : user.name
                  }}
                  {{
                    user.idTokenClaims.family_name
                      ? user.idTokenClaims.family_name
                      : ''
                  }}
                </span>
                <span class="account-role">
                  {{ user.username }}
                </span>
              </div>
              <i class="ibzb-down"></i>
            </a>
            <template #overlay>
              <a-menu class="header-dropdown">
                <a-menu-item>
                  <router-link :to="{ name: 'master-project' }">
                    <setting-outlined 
                      class="me-4 ms-1"
                      :style="{ fontSize: '16px' }"
                    />
                    <span class="text">Master Project</span>
                  </router-link>
                </a-menu-item>
                <a-menu-item class="logout">
                  <a class="d-flex justify-content-center" @click="logout">
                    <span class="text">Sign Out</span>
                    <img :src="img_logout" />
                  </a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <div v-else>
            <router-link :to="{ name: 'login' }" class="color-primary">
              Login
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </a-layout-header>
  <!-- <v-breadcrumb :breadcrumb="breadcrumb" :propsMode="breadcrumbPropsMode">
    <span class="text-sm text-black-1" v-text="defaultTitle"></span>
  </v-breadcrumb> -->
</template>
<script>
import CommonMixin from '@/mixin/CommonMixin'
// import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

import LocaleMixin from '@/mixin/LocaleMixin'
import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)

import * as AntDesignIcons from '@ant-design/icons-vue'
const { SettingOutlined } =
  AntDesignIcons

export default {
  name: 'Header',
  mixins: [CommonMixin, LocaleMixin],
  components: {
    SettingOutlined,
  },
  props: {
    appList: Array,
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    breadcrumbPropsMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // logo: require('@/assets/images/logo-notext.png'),
      // avatar: require('@/assets/images/avatar.png'),
      img_logout:
        config.bzbBlobUrl + '/themebackoffice/assets/img/logout.svg',
      img_manual:
        config.bzbBlobUrl + '/themebackoffice/assets/img/manual.svg',
      img_logo:
        config.bzbBlobUrl + '/themebackoffice/assets/img/logo-icon.svg',
      showBtnAddMS: false,
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(newValue) {
        this.$store.commit('setCollapsed', newValue)
      },
    },
    user: {
      get() {
        return Cache.getCache('msUserData')
      },
    },
  },
  created() {
    if (Cache.getCache('is_MSUser') == 'true') {
      this.showBtnAddMS = true
    }
  },
  methods: {
    hasSlot: function (name = 'default') {
      return !!this.$slots[name]
    },
    logout() {
      this.value = false
      Cache.removeCache('msToken')
      Cache.removeCache('setMenuProduct')
      localStorage.removeItem('bzbsFeatures')
      sessionStorage.removeItem('permission')
      sessionStorage.removeItem('permissionbyapp')
      sessionStorage.removeItem('isSearch')
      Cache.setCache('loading', false)
      const logoutRequest = {
        postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
      }

      myMSAL.logoutRedirect(logoutRequest)
    },
    gotoSelectZone() {
      this.$router.push({ name: 'signinzone' })
    },
    handleChange(e) {
      this.$router.replace({
        name: this.$route.name,
        params: { appid: e },
      })
      location.reload()
    },
    gotoMSflow() {
      Cache.set('msStatus', 'migrate')
      Cache.set('redirectUrl', this.$route.path)
      this.$router.push({ name: 'redirect-ms' })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_mixin.scss';
@import '@/style/base/base.scss';
.tag-beta {
  width: 150px;
  position: absolute;
  top: 50%;
  left: 52%;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: $color-primary;
  img {
    transform: rotate(7deg);
  }
  @include breakpoint-max($tablet-xs) {
    display: none;
  }
}
</style>
