import { createApp } from 'vue'
import Antd from 'ant-design-vue/lib'
import App from './App.vue'
import router from './router'
import store from './store'
import * as rxjs from 'rxjs'

// import AppConfig from "@/config/vueconfig.js";
import ErrorApiMixin from '@/mixin/ErrorApiMixin.js'
import CommonMixin from '@/mixin/CommonMixin.js'
import Tracking from '@/plugins/Tracking.js'
import Filter from '@/plugins/Filter.js'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
// import 'ant-design-vue/dist/antd.min.css'
import './index.css'
import '@splidejs/splide/dist/css/splide.min.css'
import VueApexCharts from 'vue3-apexcharts'
// import VueGoogleMaps from '@fawmi/vue-google-maps'

//#region global component
import VSvgIcon from '@/components/Icon/SvgIcon'
import VOverlay from '@/components/Overlay/Overlay'
import VLoading from '@/components/Loading/Loading'
import VLoadingTable from '@/components/Loading/LoadingTable'
import VDialog from '@/components/Dialog/Dialog'
//#endregion
import 'ant-design-vue/dist/antd.less'
// import 'ant-design-vue/dist/antd.css';

// import ClickOutsideDirective from "@/plugins/directive/clickOutside"
import VueClickAway from 'vue3-click-away'
//#endregion
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import {
  EyeOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons-vue'

import { quillEditor } from 'vue3-quill'

createApp(App)
  .use(BootstrapVue3)
  .use(rxjs)
  .use(Antd)
  .use(store)
  .use(router)
  .use(Tracking)
  .use(Filter)
  .use(Filter)
  .use(quillEditor)

  // .use(VueClipboard)
  .use(VueClickAway)
  .use(VueApexCharts)
  .use(Vue3Lottie)
  .mixin(CommonMixin)
  .mixin(ErrorApiMixin)
  //#region registe global component
  .component('VSvgIcon', VSvgIcon)
  .component('VOverlay', VOverlay)
  .component('VLoading', VLoading)
  .component('VLoadingTable', VLoadingTable)
  .component('VDialog', VDialog)
  .component('apexchart', VueApexCharts)
  .component('EyeOutlined', EyeOutlined)
  .component('ArrowLeftOutlined', ArrowLeftOutlined)
  .component('DeleteOutlined', DeleteOutlined)
  .component('QuestionCircleOutlined', QuestionCircleOutlined)
  .component('SearchOutlined', SearchOutlined)
  .component('PlusOutlined', PlusOutlined)
  .component('EllipsisOutlined', EllipsisOutlined)

  .mount('#app')
