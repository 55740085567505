<template>
  <quill-editor
    v-model:value="contentData"
    contentType="html"
    :options="state.editorOption"
    toolbar="full"
    :disabled="disabled"
    @change="$emit('contentValue', contentData)"
  />
</template>
<script>
import { defineComponent, reactive, ref, onUpdated } from 'vue'
import { quillEditor } from 'vue3-quill'
export default defineComponent({
  components: {
    quillEditor,
  },
  props: {
    disabled: Boolean,
    detail: { String, default: '' },
  },
  setup(props) {
    const contentData = ref(null)
    const state = reactive({
      dynamicComponent: null,
      content: '',
      _content: '',
      editorOption: {
        placeholder: 'Please insert your content',
        readOnly: true,
        modules: {
          // toolbar: [
          //   [{ header: 1 }, { header: 2 }],
          //   [{ color: [] }, { background: [] }],
          //   ['bold', 'italic', 'underline', 'strike'],
          //   [{ size: ['small', false, 'large', 'huge'] }],
          //   [{ align: [] }],
          //   [
          //     { list: 'ordered' },
          //     { list: 'bullet' },
          //     { indent: '-1' },
          //     { indent: '+1' },
          //   ],
          //   ['link', 'code-block', 'clean'],
          // ],
        },
      },
    })
    onUpdated(() => {
      contentData.value = props.detail
    })
    return {
      state,
      contentData,
    }
  },
})
</script>
<style lang="scss">
.ql-container {
  height: 300px;
}
// .ql-align-right {
//   text-align: right;
//   img {
//     margin-left: auto;
//     margin-right: 0;
//   }
// }
// .ql-align-center {
//   text-align: center;
//   img {
//     margin-left: auto;
//     margin-right: auto;
//   }
// }
// .ql-align-left {
//   text-align: left;
//   img {
//     margin-left: 0;
//     margin-right: auto;
//   }
// }
// .ql-indent-1 {
//   padding-left: 3em;
// }
// .ql-indent-2 {
//   padding-left: 6em;
// }
// .ql-indent-3 {
//   padding-left: 9em;
// }
// .ql-indent-4 {
//   padding-left: 12em;
// }
</style>
