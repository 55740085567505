const apiConfig = {
  b2cScopes: [
    'https://prdaadb2cbuzzebees.onmicrosoft.com/97a54b71-1564-44f2-9c05-ffe072d7a737/Access',
  ],
}
const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    editProfile: 'B2C_1A_PROFILE_EDIT',
    changePassword: 'B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://prdaadb2cbuzzebees.b2clogin.com/prdaadb2cbuzzebees.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
    },
    editProfile: {
      authority:
        'https://prdaadb2cbuzzebees.b2clogin.com/prdaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT',
    },
    changePassword: {
      authority:
        'https://prdaadb2cbuzzebees.b2clogin.com/prdaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
    },
  },
  authorityDomain: 'prdaadb2cbuzzebees.b2clogin.com',
}
const local = 'vn' // Vietnam
export const config = {
  local: local,
  debug: false, //enable console.log

  // ------------- Production Settings ----------------
  api: 'api',
  adB2CUrl: `https://prd-api-active-directory-b2c-wrapper-${local}.azurewebsites.net/ppperm`,
  announcementManagementAPI: `https://${local}-api-product-announcement.buzzebees.com`,
  bzbBlobUrl: `https://prdproductization${local}.blob.core.windows.net`,

  // ------------------ End Settings ---------------------
  fbLogOnUrl: 'https://www.facebook.com/dialog/oauth?',
  fbPermissions: ['email'],
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  msalConfig: {
    auth: {
      clientId: '2563fb67-a088-4deb-af8f-c83b146d6028',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: window.location.origin + '/redirectms',
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  b2cPolicies: b2cPolicies,
  loginRequest: {
    scopes: ['openid', ...apiConfig.b2cScopes],
    // prompt: 'select_account',
  },
  tokenRequest: {
    scopes: [...apiConfig.b2cScopes],
    forceRefresh: false,
  },

  businessRule: {
    css: {
      version: 4,
    },
  },
}
