<template>
  <AppLayout :disable-menu="true">
    <a-form layout="vertical">
      <div class="create-container">
        <div class="row wrapper-container mb-0">
          <Breadcrumb
            :title="title"
            :databreadcrumbs="databreadcrumbs"
          ></Breadcrumb>
        </div>
        <a-row class="row-container">
          <a-col class="left-container">
            <div class="bg-white card-content">
              <div class="row p-0">
                <div class="content-left">
                  <div class="item-content w-100">
                    <a-form-item
                      v-bind="validateInfos.show_to_project"
                      class="flex-post"
                      label="Post for"
                    >
                      <a-select
                        v-model:value="params.show_to_project"
                        :options="state.checkedList"
                        size="middle"
                        :allowClear="true"
                        :notFoundContent="false"
                        :disabled="isDisabled"
                        placeholder="Please select Projects"
                      ></a-select>
                    </a-form-item>
                  </div>
                </div>
                <div class="content-left">
                  <div class="item-content w-100">
                    <a-form-item
                      class="flex-post"
                      name="title"
                      label="Title"
                      v-bind="validateInfos.title"
                    >
                      <a-input
                        show-count
                        v-model:value="params.title"
                        placeholder="Please input your title"
                        :maxlength="150"
                        size="small"
                        class="input-length"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="content-left mt-4">
                  <div class="item-content w-100">
                    <a-form-item
                      class="flex-post"
                      name="content"
                      label="Content"
                      v-bind="validateInfos.content"
                    >
                      <QuillEditor
                        :detail="params.content"
                        @content-value="contentEditor"
                      ></QuillEditor>
                    </a-form-item>
                    <Preview
                      :title="params.title"
                      :content="params.content"
                      :required="params.is_required_accept"
                    ></Preview>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col class="right-container">
            <div class="bg-white card-content">
              <div class="content-left">
                <div class="item-content w-100">
                  <a-form-item
                    v-bind="validateInfos.post_type"
                    class="flex-post"
                    label="Post Type"
                  >
                    <a-select
                      v-model:value="params.post_type"
                      :options="state.postTypes"
                      size="middle"
                      :allowClear="true"
                      :notFoundContent="false"
                      placeholder="Please select Post Type"
                      @change="postType"
                    ></a-select>
                  </a-form-item>
                </div>
              </div>
            </div>

            <div
              v-show="
                params.post_type != 'Term & Condition' &&
                params.post_type != 'SLA'
              "
              class="bg-white card-content mt-4"
            >
              <div class="row p-0">
                <div class="row p-0 m-0">
                  <div
                    class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 start-date"
                  >
                    <a-form-item
                      class="m-0 flex-date"
                      name="start_datetime"
                      label="Publish Date"
                      v-bind="validateInfos.start_datetime"
                    >
                      <a-date-picker
                        format="DD MMM YYYY"
                        placeholder="Start Date"
                        v-model:value="params.start_datetime"
                        :allowClear="true"
                        :disabled-date="disabledStartDate"
                        :disabled-time="disabledStartDateTime"
                        @change="onSelectStartDate"
                      />
                    </a-form-item>
                  </div>
                  <div
                    class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 d-grid align-end start-date"
                  >
                    <a-form-item
                      class="flex-date m-0"
                      name="start_datetime"
                      v-bind="validateInfos.start_datetime"
                    >
                      <a-time-picker
                        v-model:value="params.start_datetime"
                        placeholder="Start Time"
                        format="HH:mm"
                        :showNow="false"
                        :disabled-date="disabledStartDate"
                        :disabled-time="disabledStartDateTime"
                        @change="setStartDateTime"
                        @select="onSelectStart"
                      />
                    </a-form-item>
                  </div>
                  <div
                    class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 start-date mt-3"
                  >
                    <a-form-item
                      class="m-0"
                      name="end_datetime"
                      v-bind="validateInfos.end_datetime"
                    >
                      <a-date-picker
                        format="DD MMM YYYY"
                        placeholder="End Date"
                        v-model:value="params.end_datetime"
                        :allowClear="true"
                        :showNow="false"
                        :disabled-date="disabledEndDate"
                        :disabled-time="disabledEndDateTime"
                        @change="onSelectEndDate"
                      />
                    </a-form-item>
                  </div>
                  <div
                    class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 d-grid align-end start-date"
                  >
                    <a-form-item
                      class="m-0"
                      name="end_datetime"
                      v-bind="validateInfos.end_datetime"
                    >
                      <a-time-picker
                        v-model:value="params.end_datetime"
                        placeholder="End Time"
                        format="HH:mm"
                        :allowClear="true"
                        :showNow="false"
                        :disabled-date="disabledEndDate"
                        :disabled-time="disabledEndDateTime"
                        @change="setEndDateTime"
                        @select="onSelectEnd"
                      />
                    </a-form-item>
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white card-content mt-4">
              <Radio
                :title="`Required Action`"
                :required="params.is_required_accept"
                :isVertical="false"
                :disabled="isDisabled"
                @required-action="requiredAction"
              ></Radio>
            </div>
          </a-col>
        </a-row>
        <div class="bg-white text-right footer-container">
          <a-button type="primary" @click="showConfirmCreate">
            <span class="create-btn">Create Post</span>
            <img class="create-icon" src="@/assets/icons/bullhorn-white.svg" />
          </a-button>
          <!-- <img class="icon-title" src="@/assets/icons/bullhorn.svg" /> -->
        </div>
      </div>
    </a-form>
  </AppLayout>
</template>
<script setup>
import { ref, reactive, createVNode, onMounted } from 'vue'
import AppLayout from '@/components/Layout/default.vue'
import { Modal } from 'ant-design-vue'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
dayjs.locale('en')
import { QuestionCircleOutlined } from '@ant-design/icons-vue'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import QuillEditor from '../component/Editor.vue'
import Radio from '../component/Radio.vue'
import Preview from '../component/Preview.vue'
import BzbsAnnouncement from '@/core/Announcement/callapi/BzbsAnnouncement'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useError from '@/components/Utility/useError'
import { Form } from 'ant-design-vue'
const useForm = Form.useForm
const { errorApi, reToken } = useError()

const title = 'Create Post'
const store = useStore()
const router = useRouter()
// const user = ref(Cache.getCache('msUserData'))
// const startDatePreview = ref()
const databreadcrumbs = ref([
  {
    name: 'announcement',
    breadcrumbName: 'Announcement Board',
  },
  {
    name: null,
    breadcrumbName: 'Create Post',
  },
])
const state = reactive({
  indeterminate: false,
  disable: false,
  checkAll: false,
  checkedList: [],
  postTypes: [],
})
const params = reactive({
  id: '',
  title: '',
  content: '',
  is_required_accept: false,
  is_active: true,
  start_datetime: '',
  end_datetime: '',
  show_to_project: '',
  post_type: '',
  show_to_roles: [],
})

///////////////////////////// datetimepicker /////////////////////////////
let daySelectStart = ref()
let daySelectEnd = ref()
let isCurrentStartDate = ref(false)
let isCurrentEndDate = ref(false)
let hourSelectStart = ref()
let hourSelectEnd = ref()
let isDisabled = ref(false)
function disabledStartDate(startValue) {
  if (params.end_datetime) {
    let endValue = params.end_datetime
    if (!startValue || !endValue) {
      return false
    } else {
      return (
        startValue.valueOf() > endValue.valueOf() ||
        (startValue && startValue < dayjs().startOf('day'))
      )
    }
  } else {
    return startValue && startValue < dayjs().startOf('day')
  }
}
function disabledEndDate(endValue) {
  if (params.start_datetime) {
    let startValue = params.start_datetime
    if (!endValue || !startValue) {
      return false
    } else {
      return (
        startValue.valueOf() > endValue.valueOf() ||
        (endValue && endValue < dayjs().startOf('day'))
      )
    }
  } else {
    return endValue && endValue < dayjs().startOf('day')
  }
}
function onSelectStartDate(date) {
  daySelectStart.value = date.date()
  hourSelectStart.value = date.hour()
  if (date.date() === dayjs().date()) {
    if (isCurrentStartDate.value == false) {
      isCurrentStartDate.value = true
      setStartDateTime(dayjs())
    } else {
      return
    }
  } else {
    isCurrentStartDate.value = false
    return
  }
}
function onSelectEndDate(date) {
  daySelectEnd.value = date.date()
  hourSelectEnd.value = date.hour()
  if (date.date() === dayjs().date()) {
    if (isCurrentEndDate.value == false) {
      isCurrentEndDate.value = true
      setEndDateTime(dayjs().endOf('day'))
    } else {
      return
    }
  } else {
    if (params.start_datetime && date.date() == params.start_datetime.date()) {
      setEndDateTime(params.start_datetime.endOf('day'))
    } else {
      setEndDateTime(date.endOf('day'))
    }
    isCurrentEndDate.value = false
    return
  }
}
const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}
const disabledStartDateTime = () => {
  if (daySelectStart.value == dayjs().date()) {
    if (hourSelectStart.value == dayjs().hour()) {
      return {
        disabledHours: () => range(0, 24).splice(0, dayjs().hour()),
        disabledMinutes: () => range(0, 60).splice(0, dayjs().minute()),
      }
    } else {
      return {
        disabledHours: () => range(0, 24).splice(0, dayjs().hour()),
      }
    }
  } else {
    return {
      disabledHours: () => range(0, 0),
      disabledMinutes: () => range(0, 0),
    }
  }
}
const disabledEndDateTime = () => {
  if (daySelectEnd.value == dayjs().date()) {
    if (hourSelectEnd.value == dayjs().hour()) {
      return {
        disabledHours: () => range(0, 24).splice(0, dayjs().hour()),
        disabledMinutes: () => range(0, 60).splice(0, dayjs().minute() + 1),
      }
    } else {
      return {
        disabledHours: () => range(0, 24).splice(0, dayjs().hour()),
      }
    }
  } else {
    if (
      params.start_datetime &&
      daySelectEnd.value == params.start_datetime.date()
    ) {
      return {
        disabledHours: () =>
          range(0, 24).splice(0, params.start_datetime.hour()),
        disabledMinutes: () =>
          range(0, 60).splice(0, params.start_datetime.minute() + 1),
      }
    } else {
      return {
        disabledHours: () => range(0, 0),
        disabledMinutes: () => range(0, 0),
      }
    }
  }
}
function onSelectStart(date) {
  hourSelectStart.value = date.hour()
  if (date.hour() === dayjs().hour()) {
    if (isCurrentStartDate.value == false) {
      isCurrentStartDate.value = true
      setStartDateTime(date)
    } else {
      return
    }
  } else {
    isCurrentStartDate.value = false
    return
  }
}
function onSelectEnd(date) {
  hourSelectEnd.value = date.hour()
  if (
    date.hour() === dayjs().hour() &&
    date.minute() === params.start_datetime.minute()
  ) {
    if (isCurrentEndDate.value == false) {
      isCurrentEndDate.value = true
      setEndDateTime(params.start_datetime.add(1, 'minute'))
    } else {
      // setEndDateTime(date.add(1, 'minute'))
      return
    }
  } else {
    setEndDateTime(date)
    isCurrentEndDate.value = false
    return
  }
}
function changeFormatDate() {
  params.start_datetime = dayjs(
    params.start_datetime,
    'YYYY-MM-DD HH:mm:ss',
  ).set('second', 0)
  params.end_datetime = dayjs(params.end_datetime, 'YYYY-MM-DD HH:mm:ss').set(
    'second',
    0,
  )
}

///////////////////////////// set params /////////////////////////////
function setStartDateTime(dateTime) {
  params.start_datetime = dateTime
}
function setEndDateTime(dateTime) {
  params.end_datetime = dateTime
}
function contentEditor(content) {
  params.content = content
}
function postType(value) {
  if (value == 'Term & Condition') {
    params.show_to_project = 'All'
    params.is_required_accept = true
    isDisabled.value = true
    params.start_datetime = dayjs()
    params.end_datetime = dayjs().endOf('day')
  } else if (value == 'SLA') {
    params.show_to_project = 'All'
    params.is_required_accept = false
    isDisabled.value = true
    params.start_datetime = dayjs()
    params.end_datetime = dayjs().endOf('day')
  } else {
    isDisabled.value = false
    params.start_datetime = null
    params.end_datetime = null
  }
  params.post_type = value
}
function requiredAction(toggle) {
  params.is_required_accept = toggle
}

///////////////////////////// validation /////////////////////////////
const rulesRef = reactive({
  show_to_project: [
    {
      required: true,
      message: '',
    },
  ],
  title: [
    {
      required: true,
      message: '',
    },
  ],
  content: [
    {
      required: true,
      message: '',
    },
  ],
  start_datetime: [
    {
      required: true,
      message: '',
    },
  ],
  end_datetime: [
    {
      required: true,
      message: '',
    },
  ],
  post_type: [
    {
      required: true,
      message: '',
    },
  ],
})
const { validate, validateInfos } = useForm(params, rulesRef, {
  onValidate: (...args) => console.log('validate : ', ...args),
})

///////////////////////////// call api /////////////////////////////
const showConfirmCreate = () => {
  validate()
    .then(() => {
      if (
        (params.start_datetime.date() == params.end_datetime.date() &&
          params.start_datetime.hour() == params.end_datetime.hour() &&
          params.start_datetime.minute() == params.end_datetime.minute()) ||
        dayjs() > params.end_datetime
      ) {
        params.end_datetime = ''
      } else {
        Modal.confirm({
          title: 'Create Post',
          icon: createVNode(QuestionCircleOutlined),
          centered: 'true',
          width: 530,
          content: 'Confirm create post?',
          onOk() {
            callPostAnnouncement()
          },
          onCancel() {},
        })
      }
    })
    .catch(err => {
      console.log('error validation', err)
    })
}
function callGetProject() {
  return new Promise((resolve, reject) => {
    BzbsAnnouncement.apiGetProject().subscribe({
      next: res => {
        res.result.map(e => {
          var data = {}
          if (e.display == 'All') {
            data = {
              label: e.display,
              value: 'All',
            }
            state.checkedList.push(data)
          } else {
            data = {
              label: e.display,
              value: e.name,
            }
            state.checkedList.push(data)
          }
        })
        resolve(res)
      },
      error: err => {
        reject(err)
      },
    })
  })
}
function callGetPostType() {
  return new Promise((resolve, reject) => {
    BzbsAnnouncement.apiGetPostType().subscribe({
      next: res => {
        res.result.map(e => {
          var data = {}
          data = {
            label: e.name,
            value: e.value,
          }
          state.postTypes.push(data)
        })
        params.post_type = state.postTypes[0].value
        resolve(res)
      },
      error: err => {
        reject(err)
      },
    })
  })
}
function callPostAnnouncement() {
  store.commit('setLoading', true)
  return new Promise((resolve, reject) => {
    changeFormatDate()
    params.show_to_roles = 'All'
    BzbsAnnouncement.apiPostUpsertAnnouncement(params)
      .then(res => {
        store.commit('setLoading', false)
        Modal.success({
          title: 'Success',
          centered: true,
          onOk() {
            router.push({ name: 'announcement' })
          },
        })
        resolve(res)
      })
      .catch(error => {
        store.commit('setLoading', false)
        if (error.response.status == 401) {
          reToken().then(() => {
            callPostAnnouncement()
          })
        } else {
          let content = error
            ? error[0].message
            : 'Something went wrong, Please try again.'
          Modal.error({
            title: 'Error',
            centered: true,
            content: content,
            onOk() {},
          })
        }
        reject(error)
      })
  })
}
onMounted(() => {
  // Cache.getCache('msUserData')
  Promise.all([callGetProject(), callGetPostType()])
    .then(() => {
      store.commit('setLoading', false)
    })
    .catch(err => {
      store.commit('setLoading', false)
      errorApi(err.response)
    })
})
</script>
<style lang="scss">
@import '@/style/_mixin.scss';

.create-container {
  overflow-x: hidden;
  padding-bottom: 73px;
  .required-mark {
    display: inline-block;
    color: $color-danger;
    font-size: 14px;
    margin-right: 4px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
  .row-container {
    column-gap: 24px;
  }
  @media only screen and (max-width: 996px) {
    .row-container {
      row-gap: 24px !important;
    }
  }
  .left-container {
    flex: auto;
    width: 500px;
  }
  .right-container {
    width: 396px;
  }
  label {
    font-weight: normal;
  }
  .wrapper-container {
    border-radius: 12px;
    margin: 0;
  }
  .card-content {
    border-radius: 12px;
    padding: 16px;
  }
  .content-left {
    display: flex;
    justify-content: left;
  }
  .item-content {
    flex-direction: column;
  }
  .ant-input-show-count-suffix {
    position: absolute;
    margin-top: 60px;
    margin-left: -42px;
    font-size: 12px;
    width: 100px;
  }
  .radio-style {
    display: block;
  }
  .create-btn {
    padding-left: 30px;
    position: relative;
  }
  .create-icon {
    position: absolute;
    margin-top: -23px;
    margin-left: 0;
  }
  .star-color {
    color: red;
  }
  .flex-post {
    flex-direction: row !important;
  }
  .ant-picker {
    margin: 0;
    width: 100%;
  }
  .top-start-time {
    padding-bottom: 11.15px;
  }
  .ant-form-item-control-input {
    align-items: start;
  }

  .flex-post > .ant-col.ant-form-item-label {
    width: 100%;
    label.ant-form-item-required {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .flex-date > .ant-col.ant-form-item-label {
    width: 160px;
    label.ant-form-item-required {
      font-size: 16px;
      font-weight: bold;
    }
  }
  span.ant-select-selection-item {
    border-radius: 2px;
  }
  .end-date {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 996px) {
    .right-container {
      width: 100%;
    }
  }

  @media only screen and (max-width: 576px) {
    .start-date {
      margin-bottom: 10px;
    }
    .top-start-time {
      padding-bottom: 0;
      display: none;
    }
  }
}
.ant-form-item-has-error {
  .ql-toolbar.ql-snow {
    border: 1px solid #ff4d4f !important;
  }
  .ql-container.ql-snow {
    border: 1px solid #ff4d4f !important;
    border-top: none !important;
  }
}
</style>
