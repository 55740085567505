<template>
  <div class="ant-layout-sider-group">
    <div class="logo">
      <a>
        <img v-bind:src="img_logo" alt="Buzzebees Logo" />
      </a>
    </div>
    <div
      class="ant-layout-sider-overlay"
      :class="{ overlayed: !collapsed }"
      @click="collapsed = !collapsed"
    ></div>
    <a-layout-sider v-if="!collapsed" width="70" class="sider-mainmenu">
      <a-menu
        mode="inline"
        :theme="$store.state.themecolor"
        :selected-keys="['announcement-management']"
      >
        <template v-for="product in menuProduct">
          <a-menu-item
            v-if="product"
            :key="product.name"
            :title="product.display_name"
            :disabled="product.name == 'announcement-management'"
          >
            <a @click="gotoOtherWeb(product)">
              <img
                :src="
                  urlBlob +
                  '/themebackoffice/assets/img/menu/' +
                  product.icon +
                  '.normal.svg'
                "
                class="normal"
              />
              <img
                :src="
                  urlBlob +
                  '/themebackoffice/assets/img/menu/' +
                  product.icon +
                  '.active.svg'
                "
                class="active"
              />
              <img
                :src="
                  urlBlob +
                  '/themebackoffice/assets/img/menu/' +
                  product.icon +
                  '.disable.svg'
                "
                class="disabled"
              />
              <!-- <i class="anticon">
                <i :class="product.icon"></i>
              </i> -->
            </a>
          </a-menu-item>
        </template>
      </a-menu>
      <a-dropdown placement="right" class="dropdown-helpcenter">
        <a class="dropdownmenu" @click.prevent>
          <QuestionCircleOutlined />
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-for="(sub, index) in menuHelpCenter.menus"
              :key="index"
            >
              <span @click="gotoHelpCenter(sub.page.route_name)">
                {{ sub.page.display_name }}
              </span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </a-layout-sider>
    <a-layout-sider
      v-model:collapsed="collapsed"
      collapsible
      breakpoint="xl"
      width="280"
      :collapsed-width="70"
      :trigger="null"
      class="sider-thismenu"
      :class="{ collapsed: collapsed }"
    >
    </a-layout-sider>
  </div>
</template>
<script>
import CommonMixin from '@/mixin/CommonMixin'
import Account from '@/helper/AccountHelper'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
import { config } from '@/config/bzbsconfig.js'
var cache = new CacheHelper(ConfigManager.getCacheType())
import * as AntDesignIcons from '@ant-design/icons-vue'
const { QuestionCircleOutlined } = AntDesignIcons

export default {
  name: 'Sider',
  components: { QuestionCircleOutlined },
  mixins: [CommonMixin],
  data() {
    return {
      allProduct: cache.getCache('setMenuProduct'),
      title: 'BUZZEBEES',
      logobyapp: ConfigManager.getAppLogo(),
      appname: ConfigManager.getAppName(),
      logo: require('@/assets/imgs/logo-icon.svg'),
      powerby: require('@/assets/imgs/powerby.png'),
      trigger: require('@/assets/icons/trigger.svg'),
      currentRoute: this.$router.currentRoute,
      openKeys: [],
      current: [],
      rootSubmenuKeys: [],
      menuList: [],
      setMenu: false,
      dataPermission: [],
      setperrmission: false,
      permissionbyapp: [],
      img_logo: config.bzbBlobUrl + '/themebackoffice/assets/img/logo-icon.svg',
      urlBlob: config.bzbBlobUrl,
      cssVersion: config.businessRule.css.version,
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(newValue) {
        this.$store.commit('setCollapsed', newValue)
      },
    },
    user: {
      get() {
        return Account.getUserCache()
      },
    },
    menu: {
      get() {
        return this.menuList
      },
    },
    menuProduct: {
      get() {
        return this.$store.state.menuProduct
      },
    },
    menuHelpCenter: {
      get() {
        return this.$store.state.menuHelpCenter
      },
    },
  },
  mounted() {
    if (!this.setMenu) {
      this.setActiveMenu()
    }
    this.datapermission = JSON.parse(sessionStorage.getItem('permission'))
    if (this.datapermission) {
      this.permissionbyapp = this.datapermission.features.map(x => x.name)
    }
    this.permissionbyapp
  },
  created() {
    this.setMenu = false
    // this.setPermission()
    this.setActiveMenu()
    this.handleLoading(false)
  },
  methods: {
    filterMeniPermission(menu) {
      return (
        menu.filter(f => this.permissionbyapp.includes(f.permissionKey)) || []
      )
    },
    setActiveMenu() {
      if (this.menu) {
        this.menu.forEach(m => {
          if (m.sub_menu.length > 0) {
            m.sub_menu.forEach(sm => {
              if (this.currentRoute.path.includes(sm.path)) {
                this.current = [sm.path]
                this.openKeys = [m.sequence]
              }
            })
          } else {
            if (this.currentRoute.path.includes(m.path)) {
              this.current = [m.path]
            }
          }
        })
      }
    },
    checkSubMenu(submenu) {
      return submenu.filter(
        subitem => subitem.route_name == this.currentRoute.name.toLowerCase(),
      )
    },
    onOpenChange(openKeys) {
      if (!this.openKeys.includes(openKeys.key)) {
        this.openKeys = [openKeys.key]
      } else {
        this.openKeys = []
      }
    },
    // handleClick(e) {
    //   this.current = e.key
    // },
    selectApp() {
      cache.removeCache('app')
      sessionStorage.removeItem('permission')
      sessionStorage.removeItem('permissionbyapp')
      this.$store.commit('setIsSearch', false)
      this.$router.push({
        name: 'select_application',
      })
    },
    gotoOtherWeb(menu) {
      window.location = menu.url + '/redirectms'
    },
    gotoHelpCenter(route_name) {
      window.location =
        this.menuHelpCenter.url +
        '/redirectms?target_page=' +
        encodeURIComponent(route_name)
    },
  },
}
</script>
<style lang="scss" scoped>
.sider-thismenu {
  .box-scoll-menu {
    height: calc(100% - 127px) !important;
  }
}
.font-pls {
  font-size: 12px;
  color: #777;
}
.i-collapsed {
  font-size: 24px;
}
.btn-select-app {
  color: #1890ff !important;
  text-decoration: underline;
  font-style: italic;
}
</style>
